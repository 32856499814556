.collapsible {
    background-color: #eee;
    color:#444;
    cursor: pointer;
    padding: 18px;
    width:100%;
    border:none;
    text-align: left;
    outline:none;
    font-size: 15px;
}

.active, .collapsible:hover{
    background-color: #ccc;


}
.showcontents{
    display: block;
    background-color: #f1f1f1;
}
.content{
    padding: 0 18px;
    display:none;
    overflow: hidden;
    background-color: #f1f1f1;
    transition: max-height 0.2s ease-out;
}
.headers {
    background-color: rgb(11, 11, 11);
    padding: 15px;
    color: blue;
    z-index: 1;
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
}

.icon {
    display: none;
}

.menus {
    display: block;
    overflow: hidden;
    height: min-content;
}

ul {
    list-style: none;
    text-align: center;
}

li {
    display: inline-block;
}


/* .menu {
    background-color: transparent;
    color: white;
    padding: 20px;
    font-size: 16px;
    margin: 5px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.header-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.header-content a {
    color: black;
    background-color: transparent;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.header-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .header-content {
    display: block;
}

.dropdown:hover .menu {
    background-color: #df9e27;
} */

@media screen and (max-width:600px) {
    .menus {
        display: none;
    }
    .menus-responsive li {
        display: block;
    }
    .menus-responsive {
        height: auto;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    .menus-responsive ul {
        text-align: right;
    }
    .icon {
        position: absolute;
        display: block;
        right: 0;
        top: 0;
    }
}
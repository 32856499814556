.contact-container {
    margin-top: 100px;
    padding: 20px;
    width: 90%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
}

.contact-video {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 25px;
    padding: 10px;
    filter: blur(5px);
    width: 700px;
}

.contact-tabs {
    bottom: 0;
    background: transparent;
    color: #f1f1f1;
    width: 90%;
    padding: 20px;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .contact-container {
        margin-top: 100px;
        width: 80%;
        position: relative;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .contact-tabs {
        bottom: 0;
        background: transparent;
        color: "#f1f1f1";
        width: 100%;
        padding: 2px;
        position: relative;
    }
    .contact-video {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 25px;
        padding: 10px;
        filter: blur(5px);
        max-height: "50%";
        width: 100%;
    }
}


/* @media only screen and (min-width: 600px) {
    .contact-video {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 25px;
        padding: 10px;
        filter: blur(5px);
        max-height: "50%";
        width: "100%";
    }
} */
.contacttab {
    border: 2px solid black;
    border-radius: 8px;
}

.label {
    width: 50%;
    background-color: black;
    color: white;
    font-weight: bold;
}

.contact-button {
    background-color: green;
    border: 2px solid black;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    padding: 5px;
    margin: 15px;
}

a {
    color: white;
    font-weight: bold;
    transition: font-weight 0.2s;
}

a:hover {
    color: white;
    font-weight: bolder;
}
